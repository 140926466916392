import React from 'react'
import SupplierPage from '../../modules/admintbk/SupplierPage'

const Suppliear = () => {
  return (
    <SupplierPage />
  )

}

export default Suppliear