import React from 'react'
import PelangganPage from '../../modules/admintbk/PelangganPage'

const Pelanggan = () => {
    return (
        <div>
            <PelangganPage></PelangganPage>
        </div>
    )
}

export default Pelanggan